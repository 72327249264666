import React,{ useState, useEffect }from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
//import DataService from "../services/data.service";
import {getDataAsync} from '../actions/data'
import { DataGrid } from '@material-ui/data-grid';
import Chip from '@material-ui/core/Chip';
import {Box,Paper,Typography,Avatar} from '@material-ui/core';
//import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  summarybox: {
      marginBottom: theme.spacing(3),
      width: '100%',
      height: theme.spacing(17),
      display: 'flex',
  },
  summarypaper: {
    textAlign: 'center',
    margin: theme.spacing(2),
    width: '30%',
    height: theme.spacing(9),
},
  summarypaperparent: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3),
    width: '50%',
    height: theme.spacing(15),
    display: 'flex'
  },
high: {
  color: theme.palette.getContrastText('#2CB4DF'),
  backgroundColor: '#2CB4DF',
},
low: {
  color: theme.palette.getContrastText('#1F3B57'),
  backgroundColor: '#1F3B57',
}
}));

const Premises2 = () => {
  const { payload } = useSelector((state) => state.data);
  const { apikey } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [selection, setSelection] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    if(isLoggedIn){
      dispatch(getDataAsync(apikey))
    } 
  }, [dispatch,isLoggedIn,apikey]);
  
  if (!isLoggedIn) {
    return <Redirect  push to="/login" />;
  }

  function SummaryBox(props) {
    let minute = _.sumBy(props.data, function(o) { return o.serviceLevel === 'minute' ? 1:0 });
    let hour = _.sumBy(props.data, function(o) { return o.serviceLevel === 'hourlevel' ? 1:0 });
    let other = _.sumBy(props.data, function(o) { return o.serviceLevel !== 'hourlevel' && o.serviceLevel !== 'minute' ? 1:0 });
    let devices = _.sumBy(props.data, function(o) { return o.devices.length });

    let minutedevices = _.sumBy(props.data, function(o) { return o.serviceLevel === 'minute' ? o.devices.length :0 });
    let hourdevices = _.sumBy(props.data, function(o) { return o.serviceLevel === 'hourlevel' ? o.devices.length :0 });

    return <Box className={classes.summarybox} >
      <Paper className={classes.summarypaperparent}>
        <Paper className={classes.summarypaper}><Typography variant="button">Premises Total</Typography><Typography variant="h4">{ props.data.length}</Typography></Paper>
        <Paper className={classes.summarypaper}><Typography variant="button">Minute Level</Typography><Typography variant="h5">{minute}</Typography></Paper>
        <Paper className={classes.summarypaper}><Typography variant="button">Hour Level</Typography><Typography variant="h5">{ hour}</Typography></Paper>
      </Paper>

      <Paper className={classes.summarypaperparent}>
        <Paper className={classes.summarypaper}><Typography variant="button">Devices Total</Typography><Typography variant="h4">{ devices}</Typography></Paper>
        <Paper className={classes.summarypaper}><Typography variant="button">Minute Devices</Typography><Typography variant="h5">{minutedevices}</Typography></Paper>
        <Paper className={classes.summarypaper}><Typography variant="button">Hour Devices</Typography><Typography variant="h5">{ hourdevices}</Typography></Paper>
      </Paper>
      </Box>
  }

  const statesColor = {}
  const columns = [

    { field: 'name', headerName: 'Premise', width: 420,
    renderCell: (params) => {
      let s = []
      let name = params.getValue('name') || ''
      return <Tooltip title={name} arrow><span>{name}</span></Tooltip>
    } },
    { field: 'serviceLevel', headerName: 'Level', width: 100,
    renderCell: (params) => {
      let level = params.getValue('serviceLevel') || ''
      let avatar = <Avatar>N</Avatar>
      let name = ""
      switch (level) {
        case 'minute':
          avatar = <Avatar className={classes.high}>min</Avatar>
          name = level
          break;
        case 'default':
          avatar = <Avatar >-</Avatar>
          name = level
          break;  
        case 'hourlevel':
          avatar = <Avatar className={classes.low}>hour</Avatar>
          name = level
          break;     
        default:
          avatar = <Avatar>-</Avatar>
          name = level
          break;
      }

      return <Tooltip title={name} >{avatar}</Tooltip>
    } },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'postOffice', headerName: 'Post Office', width: 120 },
    { field: 'zipCode', headerName: 'Zip code', width: 90 },
    {
      field: 'devices',
      headerName: 'Devices',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 300,
      renderCell: (params) => {
        let s = []
        let devices = params.getValue('devices') || []
        devices.forEach(p => {
          //s+=`${p.deviceId}(${p.state}) `
          let color =  p.state === 'active' ? 'secondary' : 'default'
          if(p.state==='no-state'){
            s.push(<Chip key={p.deviceId} style={{ marginLeft: 5 }} variant="outlined" color="primary" size="small" label={p.deviceId} />)
          } else {
            s.push(<Chip key={p.deviceId} style={{ marginLeft: 5 }} color={color} size="small" label={p.deviceId} />)
          }
          
        });
        return s
      }
    }
  ];

  if(selection.length){
    let id = selection[0].id
    return <Redirect push to={`/premise/${id}`} />;
  }
 

  return (
    payload && payload.data ? <Box style={{ margin:'20px', height: '100vh', width: '100%', backgroundColor:'white', padding:'10px', paddingBottom:'20vh'}}>
      <SummaryBox data={payload.data}/>
      
      <DataGrid style={{backgroundColor:'white'}} rows={payload.data} columns={columns} pageSize={15} 
       onSelectionChange={(newSelection) => {
        return setSelection(newSelection.rows);
      }} 
     />
    </Box> : <Box style={{ margin:'20px', width: '100%' }}><LinearProgress /></Box>
  );
};

export default Premises2;
