export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const GET_DATA_FAIL = "GET_DATA_FAIL";
export const CLEAR_DATA = "CLEAR_DATA";

export const GET_PREMISE_DATA_SUCCESS = "GET_PREMISE_DATA_SUCCESS";
export const GET_PREMISE_DATA_FAIL = "GET_PREMISE_DATA_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";



