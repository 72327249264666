import axios from "axios";
import authHeader from "./auth-header";
import config from "../helpers/config.js";
const API_URL = config.API_SERVER_URL + '/info/';

//headers: authHeader()
const login = (apikey) => {
  //console.log("login", apikey)
  return axios
    .get(API_URL + "login",{ headers: authHeader(apikey) })
    .then((response) => {
      localStorage.setItem("apikey", apikey);
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("apikey");
};

export default {
  login,
  logout,
};
