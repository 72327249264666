import { GET_DATA_SUCCESS, GET_DATA_FAIL,GET_PREMISE_DATA_SUCCESS,GET_PREMISE_DATA_FAIL,CLEAR_DATA } from "../actions/types";

const initialState = {payload:"", dataLoaded:false};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DATA_SUCCESS:
      return { payload, dataLoaded:true};
    case GET_DATA_FAIL:
      return { payload: "", dataLoaded:false};

    case GET_PREMISE_DATA_SUCCESS:
      return { payload:payload.data };
    case GET_PREMISE_DATA_FAIL:
      return { payload: "" };  

    case CLEAR_DATA:
        return { payload:{} };   

    default:
      return state;
  }
}
