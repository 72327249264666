import React,{ useEffect, useState }from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams} from 'react-router-dom';
import {getPremise} from '../actions/data'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import MuiAlert from '@material-ui/lab/Alert';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon_ from '@material-ui/icons/Info';
import InfoIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import NotFoundImage from '../images/noimage.jpg'
import Chip from '@material-ui/core/Chip';
import config from "../helpers/config.js";

import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import CardActions from '@material-ui/core/CardActions';

const API_URL = config.API_SERVER_URL + '/info/';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    minWidth: 275,
    //maxWidth: '90%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '90%',
    paddingTop:'20px'
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: 0,
    paddingTop: '86.25%', // 56.25% 16:9
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  table: {
    minWidth: 200,
  },
  devicecard:{
    minWidth: 250,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Premise = () => {
  const { payload } = useSelector((state) => state.data);
  const { apikey } = useSelector((state) => state.auth);
  const { message } = useSelector(state => state.message);
  const { isLoggedIn } = useSelector(state => state.auth);
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const cols = desktop ? 6 : 12


  //console.log(cols, desktop)
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");

  const dispatch = useDispatch();
  const classes = useStyles();
  let { id } = useParams();

  //console.log("PREMISE ID", id)
  //console.log("PREMISE PAYLOAD", payload)

  useEffect(() => {
    if(id && apikey){
     dispatch(getPremise(id))
    }
  }, [dispatch]);
  
 /*if (!isLoggedIn) {
    return <Redirect push to="/login" />;
  }*/
  
  const handleOpen = (i) => {
    //console.log(i)
    setImage(i)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  let imageData =[];
  if(payload && payload.deviceImages){
  for (let index = 0; index < payload.deviceImages.length; index++) {
    const device = payload.deviceImages[index];
    let tile = { device: device.deviceId, img: NotFoundImage, time:"No available", exists:false, datas:[]}
    if(device._id){
      //tile.img =`${API_URL}latestimage/${device._id}/${device.deviceId}/${apikey}`
      tile.img =`${API_URL}latestimage/${device._id}/${device.deviceId}`
      tile.time = device.timestamp
      tile.exists = true
      tile.datas =  payload.datas && payload.datas[device.deviceId] ? payload.datas[device.deviceId] : []
    }
    imageData.push(tile)
  }
}
const devicestates = payload.states || []


  return (
<div className={classes.root}>
    <Card className={classes.gridList} variant="outlined">
    <CardContent>
      <Typography variant="h5" component="h2">
        {payload.name}
      </Typography>
      <Typography className={classes.pos} color="textSecondary">
      {payload.address} {payload.postOffice} {payload.zipCode}
      </Typography>
      <Typography variant="body2" component="div">
      {devicestates.map((s) => {
        let color =  s.state === 'active' ? 'secondary' : 'default';
        return <Chip key={s.deviceId} style={{ marginLeft: 5 }} color={color} size="small" label={s.deviceId} />
      })}
      </Typography>
      <Grid style={{paddingTop:20}}container spacing={3}>

      {imageData.map((tile) => (
          <Grid  item xs={cols} key={tile.img} >
          <Card className={classes.devicecard}>
          <CardHeader title={tile.device} subheader={tile.time} action={
                tile.exists ? <IconButton onClick={()=>handleOpen(tile.img)} aria-label="settings">
                  <InfoIcon />
                </IconButton>: ""
              }  />
            <CardActionArea>
              <CardMedia
                component="img"
                alt={tile.device}
                height="340"
                image={tile.img}
                title={tile.device}
                onClick={()=>handleOpen(tile.img)}
              /></CardActionArea>
              <CardActions disableSpacing>
              <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <TableContainer component={Paper}>
                  <Table size="small" className={classes.table} aria-label="values table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Time</TableCell>
                        <TableCell align="left">Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tile.datas.map((row) => (
                        <TableRow key={row.endTime}>
                          <TableCell align="left">{row.endTime}</TableCell>
                          <TableCell align="left">{row.last}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Collapse>
          </Card>     



          </Grid>
        ))}
      </Grid> 



    </CardContent>
  </Card> 
   {message && <Alert severity="error">{message} </Alert>}
   
   <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent><center><img src={image} alt="device" /></center></DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
         
      </Dialog>
   </div>
  );
};

export default Premise;
