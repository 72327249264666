import {
  GET_DATA_SUCCESS,
  GET_DATA_FAIL,
  SET_MESSAGE,
  GET_PREMISE_DATA_SUCCESS,
  GET_PREMISE_DATA_FAIL,
  CLEAR_DATA
} from "./types";

import DataService from "../services/data.service";

export const getData = apikey => dispatch => {
  //console.log("ACTION getData", apikey)
  return DataService.getData(apikey).then(
    (data) => {
      dispatch({
        type: GET_DATA_SUCCESS,
        payload: { apikey, data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_DATA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getDataAsync =  apikey => async dispatch => {
  //console.log("ACTION getDataasync", apikey)
  try {
    let data = await DataService.getData(apikey)
  dispatch({
    type: GET_DATA_SUCCESS,
    payload: { apikey, data },
  });
  } catch (error) {
    const message =
    (error.response && error.response.data) ||
    error.message ||
    error.toString();

      dispatch({
        type: GET_DATA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  }
};

export const getPremise =  (id) => async dispatch => {
  //console.log("ACTION getPremise", id)
  try {
    let data = await DataService.getPremise(id)
  dispatch({
    type: GET_PREMISE_DATA_SUCCESS,
    payload: { data },
  });
  } catch (error) {
    const message =
    (error.response && error.response.data) ||
    error.message ||
    error.toString();

      dispatch({
        type: GET_PREMISE_DATA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  }
};

export const clearData = () => ({
  type: CLEAR_DATA
});

/*export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};*/

