import axios from "axios";
import authHeader from "./auth-header";
import config from "../helpers/config.js";
const API_URL = config.API_SERVER_URL + '/info/';

const getData = (apikey) => {
  //console.log("getData", apikey)
  return axios
    .get(API_URL + "all",{ headers: authHeader(apikey) })
    .then((response) => {
      return response.data;
    });
};
const getPremise = (id) => {
  //console.log("getPremise", id, authHeader())
  return axios
    .get(API_URL + "id/" + id,{ headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

export default {
  getData,
  getPremise
};
