import React, { useState, useRef, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';

import {Avatar, Button,TextField} from '@material-ui/core';
import {Typography} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

import { login } from "../actions/auth";
import { clearMessage } from "../actions/message";
import { clearData } from "../actions/data";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = (props) => {
  const classes = useStyles();
  const form = useRef();
  const checkBtn = useRef();

  const [loading, setLoading] = useState(false);
  const [apikey, setapikey] = useState("");

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(clearMessage());
      dispatch(clearData()); 
  }, [dispatch]);

  const onApiKey = (e) => {
    const apik = e.target.value;
    setapikey(apik.trim());
    setLoading(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(login(apikey.trim()))
  };

  if (isLoggedIn) {
    return <Redirect push to="/premises2" />;
  }

  return (
    <div className={classes.paper}>
    <Avatar className={classes.avatar}>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
    Log In
    </Typography>
    <form className={classes.form} onSubmit={handleLogin} ref={form}>
      <TextField
        onChange={onApiKey}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="apikey"
        label="Smartvatten Api-Key or Partner-Key"
        name="apikey"
        autoComplete="apikey"
        autoFocus
      />
     
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={loading}
        ref={checkBtn}>
        Log In
      </Button>

      {message && <Alert severity="error">{message} </Alert>}
    </form>
  </div>
  );
};

export default Login;
