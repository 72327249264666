import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "./types";

import AuthService from "../services/auth.service";

export const login__ = (apikey) => (dispatch) => {
  return AuthService.login(apikey).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { apikey, data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login =  apikey => async dispatch => {
  //console.log("ACTION loginAsync", apikey)
  try {
    let data = await AuthService.login(apikey)
    //console.log("ACTION loginAsync SUCCESS")
  dispatch({
    type: LOGIN_SUCCESS,
    payload: { apikey, data },
  });
  } catch (error) {
    //console.log("ACTION loginAsync ERROR")
    const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  }
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

/*export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};*/

