import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Link } from "react-router-dom";
import Login from "./components/Login";
import Premises2 from "./components/Premises2";
import Premise2 from "./components/Premise2";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";
import {AppBar,Toolbar,Typography,Button,IconButton,Container, Box} from '@material-ui/core';
import {blue, green } from '@material-ui/core/colors';
//import {Menu} from '@material-ui/icons';
//import {ArrowBack} from '@material-ui/icons';
import {Home} from '@material-ui/icons';

import { createMuiTheme, ThemeProvider, makeStyles  } from '@material-ui/core/styles';
/*const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: blue[500],//'#0FB2EB',
    },
    contrastThreshold: 2,
    secondary: {
      // This is green.A700 as hex.
      main: green[500] //'#11cb5f',
    },
  },
});*/

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#002458',//'#1F3B57',
      },
      contrastThreshold: 2,
      secondary: {
        main: '#96C62F',//#green[500]//'#FBC364',
      },
    },
    typography: {
      fontFamily: [
        'Montserrat',
        'Arial',
        'sans-serif'
      ].join(','),
    }
  }
);


const useStyles = makeStyles((theme) => ({
  root: {
    height:'120vh',
    flexGrow: 1,
    backgroundImage: `url(${process.env.PUBLIC_URL + '/smart_wave2.svg'})`,
    backgroundAttachment: "fixed",
    //backgroundPosition: "center bottom",
    backgroundPositionY: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1, 
  },
  footer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    textAlign: "center"
  }
}));

const App = () => {
  const classes = useStyles();
  const { apikey } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); 
    });
  }, [dispatch]);

  history.listen((location) => {
   // console.log("LOCATION", location)
  })


  const logOut = () => {
    dispatch(logout());
    history.push("/login")
  };
 
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
      <Box className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
          <Home onClick={()=> history.push("/login")}/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
             
          </Typography>
          <img src= {`${process.env.PUBLIC_URL + '/Smartvatten_rgb_wide_white.svg'}`} height="40px"/>
          <Typography variant="h7" className={classes.title}></Typography>
          {!apikey ? <Button onClick={()=> history.push("/login")} color="inherit">Login</Button> : <Button onClick={logOut} color="inherit">Change Key</Button>}
        </Toolbar>
      </AppBar>
      <Container>
        <Switch>
          <Route exact path={"/premises2"} component={Premises2} />
          <Route exact path={["/", "/login"]} component={Login} />
          <Route exact path="/premise/:id" component={Premise2} />
        </Switch>
      </Container>
      </Box>
      </ThemeProvider>
    </Router>

  );
};

export default App;
